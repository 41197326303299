module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","cs"],"path":"/home/runner/work/konrady/konrady/apps/konrady/src/intl","defaultLanguage":"cs"},
    },{
      plugin: require('../../../packages/gatsby-plugin-setup/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/favicon.png","name":"Konrady","short_name":"Konrady","start_url":"/","background_color":"#173740","theme_color":"#F86B000018ff","display":"standalone"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/home/runner/work/konrady/konrady/node_modules/gatsby-remark-images","id":"1b1ba28e-440d-533f-8e6c-5b8e5a8cdb2c","name":"gatsby-remark-images","version":"3.1.44","pluginOptions":{"plugins":[],"maxWidth":1035,"backgroundColor":"none"},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/home/runner/work/konrady/konrady/packages/gatsby-theme-konrady/src/templates/MdxPage.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1035,"backgroundColor":"none"}}]},
    },{
      plugin: require('../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1035,"backgroundColor":"none"},
    },{
      plugin: require('../../../packages/gatsby-theme-konrady/gatsby-browser.js'),
      options: {"plugins":[],"intlOptions":{"languages":["en","cs"],"path":"/home/runner/work/konrady/konrady/apps/konrady/src/intl","defaultLanguage":"cs"},"siteMetadata":{"author":"Tomáš Konrády","description":"","title":"FastAI Docs examples"}},
    }]
